






























import Vuelidate from 'vuelidate';
import BhInput from '@/components/BhInput.vue';
import BhTextarea from '@/components/BhTextarea.vue';
import clickInside from '@/utils/clickInside';
import {Component, Vue} from 'vue-property-decorator';
import {email, required} from 'vuelidate/lib/validators';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {BhInput, BhTextarea},
  validations: {
    form: {
      email: {required, email},
      text: {required},
    },
  },
  directives: {
    clickInside,
  },
})
export default class ContactUs extends Vue {
  form = {
    email: '',
    text: '',
  };
  success = false;
  get emailErrorMessage(): string {
    return !this.$v.form.email?.required ? 'Email is required field' : 'Enter valid email';
  }
  get textErrorMessage(): string {
    return !this.$v.form.text?.required ? 'Message is required field' : '';
  }
  closeModal(): void {
    this.$emit('closeModal');
  }
  onSumbit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    vxm.user.contactUs(this.form).then(() => {
      this.success = true;
    });
  }
}
