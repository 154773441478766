










import {Component, Vue} from 'vue-property-decorator';
import Faq from '@/components/Faq.vue';
import ContactUs from '@/components/modals/ContactUs.vue';

@Component({
  components: {Faq, ContactUs},
})
export default class HelpCenter extends Vue {
  showModal = false;
}
